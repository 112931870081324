import React from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import moment from "moment";

import { fullDateFormat } from "../../utils/dateFormats";
import { ProductData } from "../../types/productData";
import { ReactComponent as OrderItemsIcon } from "../../static/svg/order-items.svg";
import { RootState } from "../../types/rootState";

interface ProductShowOrderItemsButtonProps {
  sku: ProductData["sku"];
  className?: string;
}

const ProductShowOrderItemsButton: React.FC<
  ProductShowOrderItemsButtonProps
> = (props: ProductShowOrderItemsButtonProps) => {
  const { sku, className } = props;

  const allSkuOrderItems = useSelector(
    (state: RootState) => state?.alreadyOrdered?.orderItemIndex?.[sku] || {}
  );

  const validSkuOrderItems = Object.entries(allSkuOrderItems).filter(([date]) =>
    moment(date).isSameOrAfter(moment(), "day")
  );

  const hasValidSkuOrderItems =
    Array.isArray(validSkuOrderItems) && validSkuOrderItems?.length > 0;

  return (
    <>
      {hasValidSkuOrderItems && (
        <div className={clsx("product-show-order-items-button", className)}>
          <button type="button" className="product-order-items-button">
            <Tooltip
              title={
                <div className="order-item">
                  <h5>Zulaufmengen</h5>
                  <div className="order-item__items">
                    {validSkuOrderItems?.map(([date, quantity], index) => (
                      <div
                        key={`${sku}_${index}`}
                        className="order-item__items__item"
                      >
                        <span>{moment(date).format(fullDateFormat)}</span>
                        <span>{`${quantity}x`}</span>
                      </div>
                    ))}
                  </div>
                </div>
              }
              trigger={["hover", "click"]}
              overlayClassName="product-show-order-items-tooltip"
              destroyTooltipOnHide
            >
              <OrderItemsIcon className="product-order-items-button--has-items" />
            </Tooltip>
          </button>
        </div>
      )}
    </>
  );
};

export default ProductShowOrderItemsButton;
